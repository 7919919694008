export function debounce(func, wait) {
    let timeout;
    return function(...args) {
      const context = this;
      clearTimeout(timeout);
      timeout = setTimeout(() => func.apply(context, args), wait);
    };
  }

export const getExcerpt = (string, length = 50, ellipsis = '...') => {
    if (string && string.length > length) {
        return string.substring(0, length - ellipsis.length) + ellipsis;
    } else {
        return string;
    }
}

export const getErrorMessage = (code) => {
  switch (code) {
    case 'auth/email-already-in-use':
      return 'This email is already in use by another account.';
    case 'auth/user-not-found':
      return 'No user found with this email.';
    case 'auth/wrong-password':
      return 'Incorrect password.';
    case 'auth/invalid-email':
      return 'The email address is badly formatted.';
    case 'auth/user-disabled':
      return 'This user account has been disabled.';
    case 'auth/operation-not-allowed':
      return 'Email/password accounts are not enabled.';
    case 'auth/weak-password':
      return 'The password is too weak.';
    case 'auth/missing-android-pkg-name':
    case 'auth/invalid-continue-uri':
    case 'auth/unauthorized-continue-uri':
      return 'Invalid request for password reset.';
    default:
      return 'An unexpected error occurred. Please try again.';
  }
}