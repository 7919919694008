import React, { useState, useEffect } from 'react';
import Fade from '@mui/material/Fade';
import Typography from '@mui/material/Typography';

const TextPreloader = () => {

    const allActivities  = [
        "Descending into a mysterious dungeon...",
        "Negotiating with a cunning dragon...",
        "Exploring an ancient, abandoned temple...",
        "Crafting a magical artifact...",
        "Stealthily infiltrating a fortified castle...",
        "Casting a powerful spell in a duel...",
        "Saving villagers from a goblin invasion...",
        "Traveling through a dense, enchanted forest...",
        "Tracking a werewolf under a full moon...",
        "Recruiting allies at the local tavern...",
        "Interpreting cryptic prophecies...",
        "Gathering herbs for a healing potion...",
        "Hunting a bounty in the lawless frontier...",
        "Recovering lost relics for a collector...",
        "Challenging a rival adventurer's party...",
        "Learning arcane knowledge in a wizard's tower...",
        "Sailing across a monster-infested sea...",
        "Bargaining with fairies in an ethereal plane...",
        "Solving riddles to open a magic lock...",
        "Surviving the treacherous Underdark...",
        "Performing a ritual to banish evil spirits...",
        "Escaping from a trap-infested labyrinth...",
        "Disguising to blend in at a royal ball...",
        "Negotiating a truce between warring tribes...",
        "Setting up camp in a haunted graveyard...",
        "Resisting the allure of a siren's song...",
        "Outsmarting a mischievous imp...",
        "Lifting a curse from a tormented ghost...",
        "Competing in a gladiatorial tournament...",
        "Eavesdropping on a secret cult meeting...",
        "Struggling to control shape-shifting powers...",
        "Fending off a vampire's hypnotic gaze...",
        "Calming a rampaging elemental...",
        "Searching for treasure in a sunken ship...",
        "Finding the way in a shifting maze...",
        "Guarding a caravan through treacherous terrain...",
        "Locating a hidden entrance to a thieves' guild...",
        "Piloting a gnomish invention gone haywire...",
        "Climbing a giant beanstalk to a cloud castle...",
        "Unleashing a genie from a magic lamp...",
        "Strategizing a rebellion against a tyrant king...",
        "Playing a high-stakes game with a devil...",
        "Defying the gods to reclaim a lost soul...",
        "Rescuing a prince from a dragon's keep...",
        "Training with a legendary sword master...",
        "Questing for a fountain of youth...",
        "Mastering the power of a newly discovered spell...",
        "Deciphering an ancient map to a hidden realm...",
        "Fulfilling a prophecy foretold by an oracle...",
        "Facing fears in a nightmare come to life...",
        "Battling an army of the undead..."
    ];

    // Initialize activities list from all activities
    const [activities, setActivities] = useState([...allActivities]);
    const [loadingText, setLoadingText] = useState('Waking up from a long rest...');

    useEffect(() => {
        const interval = setInterval(() => {
            if (activities.length === 0) {
                // All activities used, reset activities list
                setActivities([...allActivities]);
            }
            const randomIndex = Math.floor(Math.random() * activities.length);
            const nextActivity = activities[randomIndex];
            
            // Remove used activity from list
            setActivities(activities.filter((_, index) => index !== randomIndex));
            
            setLoadingText(nextActivity);
        }, 3000);

        return () => clearInterval(interval);
    }, [activities]);

    return (
        <Fade in={true} timeout={3000}>
            <Typography variant="h6" style={{marginTop: '25px'}}>{loadingText}</Typography>
        </Fade>
    );
};

export default TextPreloader;