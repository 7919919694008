import Layout from './components/layout/layout';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import blue from '@material-ui/core/colors/blue';
import { AppStateProvider } from './context/AppState';
import { AuthProvider } from './context/AuthState';
import AdvancedSettings from './components/AdvancedSettings';
import Home from './pages/Home';
import CharacterHistory from './pages/CharacterHistory';
import MyGeneratedCharacters from './components/user/MyGeneratedCharacters';
import bgImage from './bg.jpg';

const theme = createMuiTheme({
  palette: {
    primary: blue,
  },
});

function App() {
  return (
    <Router>
      <ThemeProvider theme={theme}>
        <AuthProvider>
          <AppStateProvider>
              <div className="App" style={{backgroundImage: `url(${bgImage})`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat', backgroundPosition: 'top', backgroundAttachment: 'fixed'}}>
                <Layout>
                    <Routes>
                      <Route path="/" element={<Home />} />
                      <Route path="/character/:characterId" element={<CharacterHistory />} />
                    </Routes>
                    <AdvancedSettings />
                    <MyGeneratedCharacters />
                </Layout>
              </div>
          </AppStateProvider>
        </AuthProvider>
      </ThemeProvider>
    </Router>
  );
}

export default App;