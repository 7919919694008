import React, { useState } from 'react';
import { getAuth, createUserWithEmailAndPassword } from "firebase/auth";
import { Button, Alert, TextField, Modal, Box, Typography } from '@mui/material';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import { makeStyles } from '@material-ui/core/styles';
import { app } from './firebase/firebase';
import { getErrorMessage } from '../../../util/util';

const auth = getAuth(app);

const useStyles = makeStyles(theme => ({
  modal: {
    position: 'absolute', 
    top: '50%', 
    left: '50%',
    transform: 'translate(-50%, -50%)', 
    backgroundColor: 'rgba(255,255,255,0.95)', 
    border: '1px solid #444',
    borderRadius: '5px',
    padding: '20px',
    display: 'flex',
    flexDirection: 'column',
    gap: 2,
    width: '80%',
    maxWidth: '25em',
    [theme.breakpoints.up('md')]: {
      maxWidth: '25em',
    },
  },
}));

function RegisterForm() {
  const classes = useStyles();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [open, setOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const handleRegister = async (event) => {
    event.preventDefault();
    try {
      await createUserWithEmailAndPassword(auth, email, password);
      setOpen(false);
    } catch (error) {
      console.error(error);
      // Set the error message state
      setErrorMessage(getErrorMessage(error.code));
    }
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <React.Fragment>
      <Button variant="contained" color="secondary" style={{marginRight: '15px'}} onClick={handleOpen}>
        <PersonAddIcon style={{marginRight: '10px' }} />
        Create Account
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="register-modal"
        aria-describedby="simple-modal-description"
      >
        <Box className={classes.modal}>
          <Typography variant="h4"><strong>Create Account</strong></Typography>

          {errorMessage && (
            <Alert severity="error">{errorMessage}</Alert>
          )}
          
          <form onSubmit={handleRegister}>
              <TextField
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  label="Email"
                  required
                  fullWidth
                  margin="normal"
              />
              <TextField
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  label="Password"
                  required
                  fullWidth
                  margin="normal"
              />
              <Button type="submit" variant="contained" color="primary">
                  Create Your Account
              </Button>
          </form>
        </Box>
      </Modal>
    </React.Fragment>
  );
}

export default RegisterForm;