import React, { useState, useContext, useEffect } from 'react';
import { Grid, Box, Typography, Card, CardContent, Button } from '@material-ui/core';
/* import TestData from '../testData.json'; */
import { makeStyles } from '@material-ui/core/styles';
import { useParams } from 'react-router-dom';
import { db } from '../components/user/account/firebase/firestore';
import { doc, getDoc } from "firebase/firestore";
import Alert from '@mui/material/Alert';
import { AppState } from '../context/AppState';
import { AuthContext } from '../context/AuthState';
import { Helmet } from 'react-helmet';
import Logo from '../logo3.png';

const useStyles = makeStyles(theme => ({
    root: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'stretch',
      justifyContent: 'center',
      minHeight: '83.3vh',
      gap: '0px',
      width: '100%',
      padding: '20px 10px',
      margin: '0px',
      [theme.breakpoints.up('md')]: {
        justifyContent: 'space-between',
        flexDirection: 'row',
        alignItems: 'flex-start',
        justifyContent: 'space-between',
        padding: '0',
        maxWidth: '55em',
        margin: '0 auto',
      }, 
    },
    maxWidth: {
        width: '100%',
        maxWidth: '100%'
    },
    topColumn: {
        textAlign: 'center',
        backgroundColor: 'rgba(255,255,255,0.8)',
        padding: '0px',
        borderRadius: '5px',
        boxShadow: '0px 1px 3px rgba(255,255,255,0.6)',
        [theme.breakpoints.up('md')]: {
            padding: '0px 40px',
        }, 
    },
    leftColumn: {
      textAlign: 'left',
      backgroundColor: 'rgba(255,255,255,0.8)',
      padding: '0px 10px',
      borderRadius: '5px',
      boxShadow: '0px 1px 3px rgba(255,255,255,0.6)',
      [theme.breakpoints.up('md')]: {
        width: 'auto',
      }, 
    },
    rightColumn: {
      textAlign: 'left',
      padding: '0px 15px',
      backgroundColor: 'rgba(255,255,255,0.98)',
      borderRadius: '5px',
      boxShadow: '0px 1px 3px rgba(255,255,255,0.6)',
      maxWidth: '100%',
      [theme.breakpoints.up('md')]: {
        width: 'auto',
      }, 
    },
    largeButton: {
        padding: '5px 25px',
        fontSize: '18px',
    },
    tableRow: {
        borderBottom: '1px solid #ddd',
        padding: '5px 0px',
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%'
    },
    historyTable: {
        borderBottom: '1px solid #ddd',
        padding: '5px 0px',
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%'
    },
    historySummaryTable: {
        borderBottom: '1px solid #ddd',
        padding: '10px 0px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        width: '100%',
        gap: '10px',
        [theme.breakpoints.up('md')]: {
            flexDirection: 'row',
            justifyContent: 'space-between',
        }, 
    },
    historySummaryTableHist: {
        borderBottom: '1px solid #ddd',
        padding: '10px 0px 30px',
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        gap: '10px',
    },
    historySummaryLeft: {
        [theme.breakpoints.up('md')]: {
            flexBasis: '15%',
            width: '15%',
        }
    },
    historySummaryRight: {
        [theme.breakpoints.up('md')]: {
            flexBasis: '80%',
            width: '80%',
        }
    },
    historyHeading: {
        marginBottom: '15px',
        textAlign: 'center',
        fontSize: '32px',
        [theme.breakpoints.up('md')]: {
            fontSize: '2.125rem',
        }, 
    },
    historySummaryRow: {
        padding: '0 0 20px',
    }
  }));

const CharacterHistory = () => {

    const { handleGenerate } = useContext(AppState);
    const { getOrderedDataFrom, currentUser } = useContext(AuthContext);
    const thumb = null;
    // { character, history, thumb, onGenerate }
    const { characterId } = useParams();
    const [character, setCharacter] = useState(null);
    const [error, setError] = useState(null);
    // const { character, history } = TestData;
    const classes = useStyles();

    /* if (!character) {
        character = {Error: "There was an error building your character, please try again"}
    }
    if (!history) {
        history = {Error: "There was an error building your character history, please try again"}
    }
     */

    useEffect(() => {
        const fetchCharacter = async () => {
            try {
                const characterRef = doc(db, "characters", characterId);
                const characterSnap = await getDoc(characterRef);

            if (characterSnap.exists()) {
                setCharacter(characterSnap.data());
                setError(null);
            } else {
                setCharacter(null);
                setError("Character not found");
            }
            } catch (err) {
                setCharacter(null);
                setError(err.message);
            }
        };
        fetchCharacter();
    }, [characterId]);

    const formatValue = (value) => {
        if (typeof value === 'string') {
            if (value.includes('/') && !(/\s\/\s/.test(value))) {
                return value.split('/').join(' / ');
            }
        }
        return value;
    }
    
    const replacementDictionary = {
        "Race": "Species",
    };
    const formatKey = (value) => {
        if (typeof value === 'string') {
            if (value in replacementDictionary) {
                return replacementDictionary[value];
            }
        }
        return value;
    }


    function replaceNewLinesWithSpaces(str) {
        if (str && typeof str === 'string') { 
            return str.replace(/\n/g, '<br />');
        }
        return str;
    }

    function replaceNumberedLists(str) {
        if (str && typeof str === 'string') { 
            str = str.replace(/([2-9]\d*\. )/g, "<br/>$1");
            // Check for hyphenated lists
            str = str.replace(/(\n)- /g, "<br/>- ");
            return str;
        }
        return str;
    }

    if (error) {
        return <Grid container spacing={3} className={classes.root}>
            <Grid item xs={12}>
                <Card className={classes.topColumn}>
                    <Alert severity="error">{error}</Alert>
                </Card>
            </Grid>
        </Grid>;
    } 

    if (!character) {
        return <Grid container spacing={3} className={classes.root}>
            <Grid item xs={12}>
                <Card className={classes.topColumn}>
                    <Typography variant="h6" className={classes.historyHeading}>
                        Loading...
                    </Typography>
                </Card>
            </Grid>
        </Grid>;
    }

    const title = `Meet ${character.history.name}, the ${character.character.Race} ${character.character.Careers} | AI-Delve`;
    const description = `Come check out my awesome adventurer, ${character.history.name}, a ${character.character.Race} ${character.character.Careers} on AI-Delve!`;

    const orderedCharacter = getOrderedDataFrom(character.character);
  
    return (
        <Grid container spacing={3} className={classes.root}>
            <Helmet>
                <title>{title}</title>
                <meta name="description" content={description} />
                <meta property="og:title" content={title} />
                <meta property="og:description" content={description} />
                <meta property="og:type" content="website" />
                <meta property="og:url" content={window.location.href} />
                <meta property="og:image" content={Logo} />
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content={title} />
                <meta name="twitter:description" content={description} />
                <meta name="twitter:image" content={Logo} />
            </Helmet>
            {/* Character Name */}
            {currentUser && (
            <Grid item xs={12} className={classes.maxWidth}>
                <Card className={classes.topColumn}>
                    <CardContent>
                        <Button variant="contained" color="primary" className={classes.largeButton} onClick={handleGenerate}>Generate Again</Button>
                    </CardContent>
                </Card>
            </Grid>
            )}

            {/* Character Details */}
            <Grid item sm={4} className={classes.maxWidth}>
                <Card className={classes.leftColumn}>
                    <CardContent>
                        <Typography variant="h4" className={classes.historyHeading}>
                            Character
                        </Typography>
                        {/* Here we are assuming that character is an object with key value pairs */}
                        {Object.entries(orderedCharacter).map(([key, value]) => (
                            <Typography key={key} className={classes.tableRow}>
                                <strong style={{textTransform: 'capitalize'}}>{formatKey(key)}: </strong> <span style={{textAlign: 'right'}}>{formatValue(value)}</span>
                            </Typography>
                        ))}
                    </CardContent>
                </Card>
            </Grid>

            {/* History */}
            <Grid item sm={8} className={classes.maxWidth}>
                <Card className={classes.rightColumn}>
                    <CardContent>
                        {thumb && (
                            <img src={thumb} style={{maxWidth: '100%', margin: '10px 0'}} alt="stable-diffusion-thumb" />
                        )}
                        <Typography variant="h3" className={classes.historyHeading}>
                            {character.history.name}
                        </Typography>
                        {/* <Typography variant="h4" className={classes.historyHeading}>
                            History
                        </Typography> */}
                        <Box mb={1} key={'history.history.key'} className={classes.historySummaryTableHist}>
                            <Box mb={1} key={'history.history'}>
                                <Typography><span dangerouslySetInnerHTML={{__html: replaceNewLinesWithSpaces(character.history.history)}}></span></Typography>
                            </Box>
                        </Box>
                        {character.history.bonds && character.history.bonds.length > 0 && (
                        <Box mb={1} key={'history.bonds'} className={classes.historySummaryTable}>
                            <Typography className={classes.historySummaryLeft}><strong style={{textTransform: 'capitalize'}}>Bonds:</strong></Typography>
                            <Box mb={1} key={'history.bond.value'} className={classes.historySummaryRight}>
                                <Typography><span dangerouslySetInnerHTML={{__html: replaceNumberedLists(character.history.bonds)}}></span></Typography>
                            </Box>
                        </Box>
                        )}
                        {character.history.flaws && character.history.flaws.length > 0 && (
                        <Box mb={1} key={'history.flaws'} className={classes.historySummaryTable}>
                            <Typography className={classes.historySummaryLeft}><strong style={{textTransform: 'capitalize'}}>Flaws:</strong></Typography>
                            <Box mb={1} key={'history.bond.value'} className={classes.historySummaryRight}>
                                <Typography><span dangerouslySetInnerHTML={{__html: replaceNumberedLists(character.history.flaws)}}></span></Typography>
                            </Box>
                        </Box>
                        )}
                        {character.history["love interest"] && (
                        <Box mb={1} key={'history.loveInterest'} className={classes.historySummaryTable}>
                            <Typography className={classes.historySummaryLeft}><strong style={{textTransform: 'capitalize'}}>Love Interest:</strong></Typography>       
                            <Box mb={1} key={'history.bond.value'} className={classes.historySummaryRight}>
                                <Typography><span dangerouslySetInnerHTML={{__html: replaceNewLinesWithSpaces(character.history["love interest"])}}></span></Typography>
                            </Box>
                        </Box>
                        )}
                        {character.history.allies && character.history.allies.length > 0 && (
                        <Box mb={1} key={'history.allies'} className={classes.historySummaryTable}>
                            <Typography className={classes.historySummaryLeft}><strong style={{textTransform: 'capitalize'}}>Allies:</strong></Typography>
                            <Box mb={1} key={'history.bond.value'} className={classes.historySummaryRight}>
                                {character.history.allies.map((item, i) => (
                                    <Typography key={i} className={classes.historySummaryRow}><span dangerouslySetInnerHTML={{__html: replaceNewLinesWithSpaces(item)}}></span></Typography>
                                ))}
                            </Box>
                        </Box>
                        )}
                        {character.history.enemies && character.history.enemies.length > 0 && (
                        <Box mb={1} key={'history.enemies'} className={classes.historySummaryTable}>
                            <Typography className={classes.historySummaryLeft}><strong style={{textTransform: 'capitalize'}}>Enemies:</strong></Typography>
                            <Box mb={1} key={'history.bond.value'} className={classes.historySummaryRight}>
                                {character.history.enemies.map((item, i) => (
                                    <Typography key={i} className={classes.historySummaryRow}><span dangerouslySetInnerHTML={{__html: replaceNewLinesWithSpaces(item)}}></span></Typography>
                                ))}
                            </Box>
                        </Box>
                        )}
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    );
};

export default CharacterHistory;