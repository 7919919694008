import React, { useContext, useState } from 'react';
import { Button, Container, Grid, Typography, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { AppState } from '../context/AppState';
import { AuthContext } from '../context/AuthState';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextPreloader from '../components/TextPreloader';
import LoginForm from '../components/user/account/Login';

const useStyles = makeStyles(theme => ({
    root: {
      minHeight: '83.3vh',
      maxWidth: '47.5em',
      display: 'flex',
      alignItems: 'center',
      jjustifyContent: 'center',
      flexDirection: 'column',
      margin: '0 auto',
      gap: '100px',
      padding: '45px 20px',
      gap: '25px',
      [theme.breakpoints.up('md')]: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        padding: '0',
        gap: '50px',
      },
    },
    leftColumn: {
      textAlign: 'left',
      backgroundColor: 'rgba(255,255,255,0.8)',
      padding: '20px 40px',
      borderRadius: '5px',
      boxShadow: '0px 1px 3px rgba(255,255,255,0.6)',
      width: '70%'
    },
    rightColumn: {
      textAlign: 'center',
      display: 'flex',
      flexDirection: 'column',
      [theme.breakpoints.up('md')]: {
        paddingTop: 0,
        paddingBottom: 0,
        width: '30%',
      },
    },
    largeButton: {
        padding: '15px 25px',
        fontSize: '20px',
    },
    rootNoAuth: {
      minHeight: '83.3vh',
      maxWidth: '60em',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
      margin: '0 auto',
      padding: '45px 20px',
      gap: '25px',
      [theme.breakpoints.up('md')]: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        padding: '0',
        gap: '50px',
      },
    }, 
    leftColumnNoAuth: {
      textAlign: 'left',
      backgroundColor: 'rgba(255,255,255,0.8)',
      padding: '20px 40px',
      borderRadius: '5px',
      boxShadow: '0px 1px 3px rgba(255,255,255,0.6)',
      [theme.breakpoints.up('md')]: {
        width: '55%',
        minHeight: '250px',
      },
    },
    rightColumnNoAuth: {
      textAlign: 'center',
      backgroundColor: 'rgba(255,255,255,0.8)',
      padding: '20px 40px',
      borderRadius: '5px',
      boxShadow: '0px 1px 3px rgba(255,255,255,0.6)',
      [theme.breakpoints.up('md')]: {
        paddingTop: 0,
        paddingBottom: 0,
        width: '45%',
        minHeight: '250px',
      },
    },
  }));

  
const Home = () => {
    const classes = useStyles();
    
    const { setSettingsOpen, generating, handleGenerate } = useContext(AppState);
    const { currentUser } = useContext(AuthContext);

    return (
      <Box className={currentUser ? classes.root : classes.rootNoAuth}>
        <Box className={currentUser ? classes.leftColumn : classes.leftColumnNoAuth}>
          <Typography variant="h1" style={{fontSize: '38px'}}>
            <strong>
              <span style={{color: '#f50057'}}>AI-Delve</span>: Ignite Your Imagination
            </strong>
          </Typography>
          {generating ? (
            <React.Fragment>
              {/* <Countdown /> */}
              <TextPreloader />
            </React.Fragment>
          ) : (
          <p>Ready to embark on a legendary adventure? Welcome to AI-Delve, the D&D Character Background Generator powered by the magic of AI. Here, we weave intricate tales of bravery, mystery, and enchantment to breathe life into your characters...</p>
          )}
        </Box>
        <Box className={currentUser ? classes.rightColumn : classes.rightColumnNoAuth}>
        {generating ? (
          <React.Fragment>
            <CircularProgress />
          </React.Fragment>
        ) : (
            <LoginGate>
                <Button variant="contained" color="primary" className={classes.largeButton} onClick={handleGenerate}>Generate</Button>
                <span style={{color: '#fff', fontSize: '11px', cursor: 'pointer', marginTop: '15px', display: 'inline-block'}} onClick={() => setSettingsOpen(true)}>Or, play with the <span style={{textDecoration: 'underline'}}>settings</span></span>
            </LoginGate>
        )}
        </Box>
      </Box>
    );
  }

export default Home;


const LoginGate = ({children}) => {
  const { currentUser } = useContext(AuthContext);
  if (!currentUser) {
    return (
      <React.Fragment>
        <LoginForm display="inline" />
      </React.Fragment>
    )
  }
  return (
    <React.Fragment>
        {children}
    </React.Fragment>
  )
}