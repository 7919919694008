import React, { useState, useContext, useEffect } from 'react';
import { AppState } from '../../context/AppState';
import { AuthContext } from '../../context/AuthState';
import { 
    Button, 
    Drawer, 
    Typography 
} from '@material-ui/core';
import { makeStyles } from "@material-ui/core/styles";
import { collection, getDocs, query, where, doc, deleteDoc } from "firebase/firestore";
import { db } from './account/firebase/firestore';
import { getExcerpt } from '../../util/util';
import { Link } from '../../util/Link';
import { Dialog, DialogActions, DialogTitle, IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

// define your styles
const useStyles = makeStyles(theme => ({
    drawer: {
      [theme.breakpoints.up('md')]: {
          width: '50vw', // 50% of viewport width
          flexShrink: 0,
          maxWidth: '50em',
      },
    },
    drawerPaper: {
      [theme.breakpoints.up('md')]: {
          width: '50vw', // 50% of viewport width
          maxWidth: '50em', 
      }, 
    },
    advancedTop: {
        display: 'flex', 
        flexDirection: 'column-reverse',
        gap: '20px',
        [theme.breakpoints.up('md')]: {
            justifyContent: 'space-between', 
            alignItems: 'flex-start',
            flexWrap: 'wrap',
            flexDirection: 'row',
            gap: '0px'
        }, 
    },
    advancedWrap: {
        padding: '20px',
        [theme.breakpoints.up('md')]: {
            padding: '40px',
        }, 
    },
    characterCodexItem: {
    },
    characterCodexWrap: {
        border: '1px solid #333',
        borderRadius: '5px',
        margin: '5px 0',
        padding: '0px 10px',
        display: 'flex'
    },
    characterCodex: {
        maxHeight: '65vh',
        overflow: 'auto'
    },
    characterCodexLink: {
        color: '#444',
        textDecoration: 'none'
    },
    characterCodexDelete: {
        position: 'absolute',
        right: 0,
        top: 0
    }
}));

const MyGeneratedCharacters = () => {
    const classes = useStyles();

    const { currentUser, userData } = useContext(AuthContext);
    const { myCharactersOpen, setMyCharactersOpen, flushCharacterCodex, setFlushCharacterCodex} = useContext(AppState);
    const [characters, setCharacters] = useState([]);
    const [characterToDelete, setCharacterToDelete] = useState(null);
    const [open, setOpen] = useState(false);

    const handleDrawerClose = () => {
        setMyCharactersOpen(false);
    };

    useEffect(() => {
        if (currentUser?.uid && flushCharacterCodex) {
            setFlushCharacterCodex(false);
            fetchCharacters();
        }
    }, [currentUser, flushCharacterCodex, setFlushCharacterCodex]);

    const fetchCharacters = async () => {
        // Query Firestore for the characters belonging to the current user
        const charactersQuery = query(collection(db, 'characters'), where('owner', '==', currentUser.uid));
        const charactersSnapshot = await getDocs(charactersQuery);
        // Map each document into a character object
        const fetchedCharacters = charactersSnapshot.docs.map(doc => {
            return { id: doc.id, ...doc.data() };
        });
        // Set the characters state
        setCharacters(fetchedCharacters);
    };

    async function deleteCharacter(characterId) {
        const characterDoc = doc(db, "characters", characterId);
        await deleteDoc(characterDoc);
        fetchCharacters();
    }

    const handleClose = () => {
        setOpen(false);
    };

    const handleClickOpen = (id) => {
        setCharacterToDelete(id);
        setOpen(true);
        // Open dialog code
    }
    
    const handleDelete = async () => {
        if (characterToDelete !== null) {
            await deleteCharacter(characterToDelete);
            setCharacterToDelete(null);
            handleClose();
        }
    };    
    
    return (
        <div>
            <Drawer 
                anchor="right" open={myCharactersOpen} onClose={handleDrawerClose}
                className={classes.drawer}
                classes={{ paper: classes.drawerPaper }}
            >
                <div className={classes.advancedWrap}>
                    <div className={classes.advancedTop}>
                        <Typography variant="h4" style={{ marginBottom: '20px' }}>Character Codex</Typography>
                        <Button 
                            variant="contained" 
                            color="default" 
                            style={{backgroundColor: '#e9e9e9', color: '#333'}}
                            onClick={handleDrawerClose} 
                        >
                            Close
                        </Button>
                    </div>
                    <Typography variant="h6" style={{ marginBottom: '20px' }}>Character Codex</Typography>
                    <Typography variant="p" style={{ padding: '20px 0' }}>
                        This is your unique collection of valiant knights, cunning rogues, and enigmatic sorcerers, all brought to life through the magic of AI. Each character has their own tale, ready to be part of your epic adventures. Explore their intricate histories and let their stories inspire countless quests in your realm of imagination!
                    </Typography>
                    <br />
                    <br />
                    {/* Render each character */}
                    <div className={classes.characterCodex}>
                        {characters.map((doc) => (
                            <div key={doc.id} className={classes.characterCodexWrap}>
                                <Link className={classes.characterCodexLink} to={`/character/${doc.id}`} callback={handleDrawerClose}>
                                    <div className={classes.characterCodexItem} key={doc.id}>
                                        <h2>{doc.history.name || `${doc.character.Race} ${doc.character.Careers} from ${doc.character.Hometown}`}</h2>
                                        <p>{getExcerpt(doc.history.history, 275)}</p>
                                    </div>
                                </Link>
                                <IconButton onClick={() => handleClickOpen(doc.id)} className={classes.characterCodexDelete}>
                                    <DeleteIcon />
                                </IconButton>
                                <Dialog
                                    open={open}
                                    onClose={handleClose}
                                    aria-labelledby="alert-dialog-title"
                                    aria-describedby="alert-dialog-description"
                                >
                                    <DialogTitle id="alert-dialog-title">
                                    {"Are you sure you want to delete this character?"}
                                    </DialogTitle>
                                    <DialogActions>
                                    <Button onClick={handleClose} color="primary">
                                        Disagree
                                    </Button>
                                    <Button onClick={handleDelete} color="primary" autoFocus>
                                        Agree
                                    </Button>
                                    </DialogActions>
                                </Dialog>
                            </div>
                        ))}
                    </div>
                </div>
            </Drawer>
        </div>
    );
};

export default MyGeneratedCharacters;