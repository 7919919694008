import React, { useState, useRef, useEffect } from 'react';
import { Box } from '@mui/material';

const TogglePill = ({onChange, weight}) => {
    const [selected, setInternalSelected] = useState(Math.round(weight / 3.333));
    const sections = ['Very Rare', 'Rare', 'Occasional', 'Often'];
    const initialPos = useRef(0);
    const moving = useRef(false);

    const handleMouseDown = (event) => {
        initialPos.current = event.touches ? event.touches[0].clientX : event.clientX;
        moving.current = true;
    }

    const handleMouseMove = (event) => {
        return;
        if (!moving.current) return;
        const clientX = event.touches ? event.touches[0].clientX : event.clientX;
        if (clientX - initialPos.current > 50) {
            setSelected((selected + 1) % sections.length);
            initialPos.current = clientX;
        } else if (initialPos.current - clientX > 50) {
            setSelected((selected + sections.length - 1) % sections.length);
            initialPos.current = clientX;
        }
    }

    const handleMouseUp = () => {
        moving.current = false;
    }

    const handleClick = (index) => {
        setSelected(index);
    }

    const setSelected = (index) => {
        setInternalSelected(index);
        onChange(parseFloat(((index+0.2) * 3.333)).toFixed(2));
        // console.log('index', (((index+0.2) * 3.333)).toFixed(2));
    }

    return (
        <Box 
            sx={{ 
                display: 'flex', 
                alignItems: 'center', 
                justifyContent: 'center', 
                width: 'fit-content', 
                padding: '0px', 
                cursor: 'pointer',
                alignItems: 'stretch'
            }}
            onMouseDown={handleMouseDown}
            onTouchStart={handleMouseDown}
            /* onMouseMove={handleMouseMove}
            onTouchMove={handleMouseMove} */
            /* onMouseUp={handleMouseUp}
            onTouchEnd={handleMouseUp}
            onMouseLeave={handleMouseUp} */
        >
            {sections.map((section, index) => {
                
                return (
                    <Box 
                        key={index} 
                        sx={{ 
                            margin: '0px 0 10px', 
                            padding: '0 10px',
                            textAlign: 'center',
                            color: index === selected ? '#fff' : '#666',
                            backgroundColor: index === selected ? {
                                backgroundImage: `linear-gradient(180deg, 
                                    ${index === 0 ? '#c94a4a, #db2121' : ''} 
                                    ${index === 1 ? '#c97a4a, #db5321' : ''} 
                                    ${index === 2 ? '#c9c04a, #db9821' : ''} 
                                    ${index === 3 ? '#69c94a, #21db44' : ''}
                                )`
                            } : '#ddd',
                            border: '1px solid #aaa',
                            transition: 'color 0.3s ease',
                            cursor: 'pointer',
                            borderRadius: index === 0 ? '8px 0 0 8px' : index === 3 ? '0 8px 8px 0' : '0',
                            boxShadow: 'inset 1px 3px 6px rgba(255,255,255,0.5)',
                            display: 'flex',
                            alignItems: 'center'
                        }}
                        onClick={() => handleClick(index)}
                    >
                        <p style={{userSelect: 'none', margin: '3px 0'}}>{section}</p>
                    </Box>
                )
            })}
        </Box>
    );
};

export default TogglePill;