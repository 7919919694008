import React, { useState } from 'react';
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { Button, Alert, TextField, Modal, Box, Typography } from '@mui/material';
import { makeStyles } from '@material-ui/core/styles';
import { app } from './firebase/firebase';
import ForgotPassword from './ForgotPassword';
import { getErrorMessage } from '../../../util/util';
const auth = getAuth(app);

const useStyles = makeStyles(theme => ({
    modal: {
      position: 'absolute', 
      top: '50%', 
      left: '50%',
      transform: 'translate(-50%, -50%)', 
      backgroundColor: 'rgba(255,255,255,0.95)', 
      border: '1px solid #444',
      borderRadius: '5px',
      padding: '20px',
      display: 'flex',
      flexDirection: 'column',
      gap: 2,
      width: '80%',
      maxWidth: '25em',
      [theme.breakpoints.up('md')]: {
        maxWidth: '25em',
      },
    },
  }));

function LoginForm({display}) {
  const classes = useStyles();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [open, setOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const handleLogin = async (event) => {
    event.preventDefault();
    try {
      await signInWithEmailAndPassword(auth, email, password);
      // User is registered and signed in
      // You could navigate to a new route now
      setOpen(false);
    } catch (error) {
      // Error occurred during registration
      console.error(error);
      setErrorMessage(getErrorMessage(error.code));
    }
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const formOnly = (<React.Fragment>
      <Typography variant="h4"><strong>Login</strong></Typography>
      {errorMessage && (
        <Alert severity="error">{errorMessage}</Alert>
      )}
      <form onSubmit={handleLogin}>
          <TextField
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              label="Email"
              required
              fullWidth
              margin="normal"
          />
          <TextField
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              label="Password"
              required
              fullWidth
              margin="normal"
          />
          <Button type="submit" variant="contained" color="primary">
              Log In
          </Button>
      </form>
      <ForgotPassword />
  </React.Fragment>
  )

  if (display === 'inline') {
    return <Box sx={{ 
      padding: '5px 0'
      }}>
      {formOnly}
      </Box>
  }

  return (
    <React.Fragment>
      <Button variant="contained" color="primary" onClick={handleOpen}>
        Login
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="register-modal"
        aria-describedby="simple-modal-description"
      >
        <Box className={classes.modal}>
        {formOnly}
        </Box>
      </Modal>
    </React.Fragment>
  );
}

export default LoginForm;