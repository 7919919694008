import React, { useContext } from 'react';
import { AppBar, Toolbar, Typography, Container, Button } from '@material-ui/core';
import { Alert } from '@mui/material';
import { AppState } from '../../context/AppState';
import { AuthContext } from '../../context/AuthState';
import Logo from '../../logo3.png';
import Donate from '../Donate';
import RegisterForm from '..//user/account/Register';
import LoginForm from '../user/account/Login';
import LogoutButton from '../user/account/LogOut';
import MyGeneratedCharacters from '../user/MyGeneratedCharacters';
import SettingsIcon from '@mui/icons-material/Settings';
import PeopleIcon from '@mui/icons-material/People';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    toolbar: {
        flexDirection: 'column',
        [theme.breakpoints.up('md')]: {
            justifyContent: 'space-between',
            flexDirection: 'row',
        },
    },
    buttonToolbar: {
        gap: '20px',
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        padding: '20px 0',
        justifyContent: 'center'
    }
}));

const Layout = ({children}) => {
    const classes = useStyles();
    const { setSettingsOpen, setMyCharactersOpen } = useContext(AppState);
    const { currentUser, authenticatedError } = useContext(AuthContext);
    return (
        <div>
            <Helmet>
                <title>Embark on a Legendary Adventure with AI-Delve | D&D Character Generator</title>
                <meta name="description" content="Welcome to AI-Delve, the D&D Character Background Generator powered by the magic of AI. Here, we weave intricate tales of bravery, mystery, and enchantment to breathe life into your characters. Ready to embark on a legendary adventure?" />
                <meta name="keywords" content="Dungeons and Dragons, D&D, character generator, AI-Delve, RPG, fantasy role-playing, character creator" />
                <meta name="author" content="dicemancer@thedigitaldungeon.online" />
            </Helmet>
            <AppBar position="static" style={{backgroundColor: '#151515'}}>
                <Toolbar className={classes.toolbar}>
                    <Link to="/" style={{display: 'block', textDecoration: 'none'}}>
                        <Typography variant="h6" style={{ flexGrow: 1, textAlign: 'left', display: 'flex', alignItems: 'center', color: '#ddd', textShadow: '2px 2px 3px #000' }}>
                            <img src={Logo} style={{height: '50px', padding: '5px', marginRight: '10px'}} alt="Random Character & History Generator" />
                            AI-Delve: Random Character Generator
                        </Typography>
                    </Link>
                    <div className={classes.buttonToolbar}>
                        {/* <Donate /> */}
                        {!currentUser && (
                            <React.Fragment>
                                <RegisterForm />
                                <LoginForm />
                            </React.Fragment>
                        )}
                        {currentUser && (
                        <React.Fragment>
                            <Button variant="contained" color="default" onClick={() => setMyCharactersOpen(true)}>
                                <PeopleIcon style={{marginRight: '10px'}} />
                                Character Codex
                            </Button>
                            <Button variant="contained" color="primary" onClick={() => setSettingsOpen(true)}>
                                <SettingsIcon style={{marginRight: '10px'}} />
                                Settings
                            </Button>
                            <LogoutButton />
                        </React.Fragment>)}
                    </div>
                </Toolbar>
            </AppBar>
            {authenticatedError && (
                <Alert severity="error">{authenticatedError}</Alert>
            )}
            <main>
                {children}
            </main>
            <footer style={{padding: '40px'}}>
                <Container maxWidth="sm">
                    <Typography variant="body1" style={{color: '#fff', fontWeight: 'bold', fontSize: '12px'}}>TheDigitalDungeon.Online © 2023</Typography>
                </Container>
            </footer>
        </div>
    )
}

export default Layout;