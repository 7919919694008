import React, { useState, useEffect, useContext, createContext, useReducer } from 'react';
import { AuthContext } from './AuthState';
import { db } from '../components/user/account/firebase/firestore';
import { addDoc, collection } from "firebase/firestore";
import { useNavigate } from 'react-router-dom';

const initialAppState = {
    settings: {},
    settingsOpen: false,
};

const appStateReducer = (state, action) => {
    switch (action.type) {
        case 'SET_SETTINGSMENU_OPEN':
        return { ...state, settingsOpen: action.payload };
        case 'SET_CHARACTERMENU_OPEN':
        return { ...state, myCharactersOpen: action.payload };
        default:
        throw new Error(`Unhandled action type: ${action.type}`);
    }
};

export const AppState = createContext();

export const AppStateProvider = ({ children }) => {
    const navigate = useNavigate();

    const [state, dispatch] = useReducer(appStateReducer, initialAppState);
    const dispatchAction = (type, payload) => dispatch({ type, payload });

    const [generating, setGenerating] = useState(false);
    const [flushCharacterCodex, setFlushCharacterCodex] = useState(true);
    
    const { requestWithAuth, currentUser, getOrderedUserData } = useContext(AuthContext);


    const handleGenerate = async () => {
      try {
          setGenerating(true);
          navigate(`/`);
          const orderedSettings = getOrderedUserData();
          const postSettings = Object.keys(orderedSettings).length > 0 ? orderedSettings : {};
          const response = await requestWithAuth(`/api/generate`, 'post', postSettings, {
            'Content-Type': 'application/json'
          });
          if (response.error) {
            alert('We encounted an error while generating, please try again');
          } else {
            if (validateGenerationOutput(response.history)) {
              addCharacterRecord(response);
              setFlushCharacterCodex(true);
            } else {
              alert('There was a network error, please generate again.');
              setGenerating(false);
            }
          }
          /* 
          const image = await axios.post(`${API_URL}/api/thumbnail`, {gender:  response.data.character.Gender, race: response.data.character.Race, careers: response.data.character.Careers, alignment: response.data.character.Alignment}, {headers: {
              'Content-Type': 'application/json'
          }});
          // image.data.thumb
          */
          setGenerating(false);
      } catch (error) {
          console.error('Error generating character: ', error);
      }
  }

  const validateGenerationOutput = (history) => {
    if (history.name === "{our character's the full name}\n" ||
        history.bond === "{character bonds}\n" ||
        history.flaws === "{character flaws}\n") {
      return false;
    }
    return true;
  }
  
  async function addCharacterRecord({character, history}) {
      // Get current user id
      const userId = currentUser.uid;

      // Prepare the document data
      const docData = {
          owner: userId,
          character,
          history
      };

      try {
          // Add a new document in the 'characters' collection
          const docRef = await addDoc(collection(db, "characters"), docData);
          console.log("Document written with ID: ", docRef.id);
          navigate(`/character/${docRef.id}`);
      } catch (e) {
          alert('Error generating character')
          console.error("Error adding document: ", e);
      }
  }

    const setSettingsOpen = (payload) => {
        dispatchAction('SET_SETTINGSMENU_OPEN', payload);
    }
    const setMyCharactersOpen = (payload) =>
        dispatchAction('SET_CHARACTERMENU_OPEN', payload);
    

    return (
      <AppState.Provider value={{ 
        ...state, 
        setSettingsOpen, 
        setMyCharactersOpen, 
        generating,
        handleGenerate,
        dispatchAction,
        flushCharacterCodex,
        setFlushCharacterCodex
      }}>
        {children}
      </AppState.Provider>
    );
};