import React, { useState } from 'react';
import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import { Button, Alert, TextField, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { getErrorMessage } from '../../../util/util';

function ForgotPassword() {
  const [email, setEmail] = useState('');
  const [open, setOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const auth = getAuth();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleForgotPassword = async (event) => {
    event.preventDefault();
    try {
      await sendPasswordResetEmail(auth, email);
      // Password reset email sent.
      // You could navigate to a new route now or give feedback to the user.
      setOpen(false);
    } catch (error) {
      // Error occurred during password reset.
      console.error(error);
      setErrorMessage(getErrorMessage(error.code));
    }
  };

  return (
    <React.Fragment>
      <Button variant="outlined" color="primary" style={{borderColor: 'transparent'}} onClick={handleClickOpen}>
        Forgot Password?
      </Button>
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Forgot Password</DialogTitle>
        {errorMessage && (
          <Alert severity="error">{errorMessage}</Alert>
        )}
        <DialogContent>
          <DialogContentText>
            To reset your password, please enter your email address here. We will send you an email with further instructions.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="email"
            label="Email Address"
            type="email"
            fullWidth
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Back to Login
          </Button>
          <Button onClick={handleForgotPassword} color="primary">
            Send Password Reset Email
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

export default ForgotPassword;