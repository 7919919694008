import React, { useState, useContext, useEffect } from 'react';
import { AppState } from '../context/AppState';
import { AuthContext } from '../context/AuthState';
import { 
    Checkbox, 
    FormControlLabel, 
    Button, 
    Drawer, 
    Accordion, 
    AccordionSummary, 
    AccordionDetails, 
    Typography 
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { makeStyles } from "@material-ui/core/styles";
import { Alert } from '@mui/material';
import TogglePill from './TogglePill';

// define your styles
const useStyles = makeStyles(theme => ({
  drawer: {
    [theme.breakpoints.up('md')]: {
        width: '50vw', // 50% of viewport width
        flexShrink: 0,
        maxWidth: '50em',
    },
  },
  drawerPaper: {
    [theme.breakpoints.up('md')]: {
        width: '50vw', // 50% of viewport width
        maxWidth: '50em', 
    }, 
  },
  advancedTop: {
    display: 'flex', 
    flexDirection: 'column-reverse',
    gap: '20px',
    [theme.breakpoints.up('md')]: {
        justifyContent: 'space-between', 
        alignItems: 'flex-start',
        flexWrap: 'wrap',
        flexDirection: 'row',
        gap: '0px'
    }, 
  },
  advancedWrap: {
    padding: '20px',
    [theme.breakpoints.up('md')]: {
        padding: '40px',
    }, 
  },
  advancedSelections: {
    display: 'flex',
    gap: '20px',
    flexWrap: 'wrap',
    paddingBottom: '20px',
    [theme.breakpoints.up('md')]: {
        paddingBottom: '15px',
    }, 
  }
}));

const AdvancedSettings = () => {
    const classes = useStyles();

    const { currentUser, userData, updateUserSettings, setUserSettings, getOrderedUserData } = useContext(AuthContext);
    const { settingsOpen, setSettingsOpen } = useContext(AppState);

    const handleDrawerClose = () => {
        setSettingsOpen(false);
    };

    const handleCheckboxChange = (key, updatedValues) => {
        updateUserSettings({ key, updatedValues });
    };

    const selectAll = (category) => () => {
        setUserSettings({ 
          ...userData.settings, 
          [category]: userData.settings[category].map(item => ({ ...item, isChecked: true })) 
        });
    };
    
    const deselectAll = (category) => () => {
        setUserSettings({ 
          ...userData.settings, 
          [category]: userData.settings[category].map(item => ({ ...item, isChecked: false })) 
        });
    };
    
    const handleSelectAll = () => {
        // Clone the settings state
        let newSettings = { ...userData.settings };
    
        // Update each category to select all items
        for (let category in newSettings) {
          newSettings[category] = newSettings[category].map(item => ({ ...item, isChecked: true }));
        }
    
        // Update the state with the new settings
        setUserSettings(newSettings);
    }
    
    const handleDeselectAll = () => {
        // Clone the settings state
        let newSettings = { ...userData.settings };
    
        // Update each category to deselect all items
        for (let category in newSettings) {
          newSettings[category] = newSettings[category].map(item => ({ ...item, isChecked: false }));
        }
    
        // Update the state with the new settings
        setUserSettings(newSettings);
    }

    // if no user, dont show settings
    if (!currentUser) {
        return null;
    }

    // if user but no settings, throw an error
    if (!userData?.settings) {
        return <Alert severity="warning">Warning: loading your settings</Alert>
    }

    const settings = getOrderedUserData();

    return (
        <div>
            <Drawer 
                anchor="right" open={settingsOpen} onClose={handleDrawerClose}
                className={classes.drawer}
                classes={{ paper: classes.drawerPaper }}
            >
                <div className={classes.advancedWrap}>
                    <div className={classes.advancedTop}>
                        <Typography variant="h4" style={{ marginBottom: '20px' }}>Advanced Settings</Typography>
                        <Button 
                            variant="contained" 
                            color="default" 
                            style={{backgroundColor: '#e9e9e9', color: '#333'}}
                            onClick={handleDrawerClose} 
                        >
                            Close Settings
                        </Button>
                    </div>
                    <Typography variant="p" style={{ padding: '20px 0' }}>Control the various settings for your random character background by selecting / deselecting available random character properties. Any checked values will be included in the randomized character generation.</Typography>
                    <br />
                    <br />
                    <div className={classes.advancedSelections}>
                        <Button variant="contained" color="default" style={{marginRight: '10px'}} onClick={handleSelectAll}>Select Everything</Button>
                        <Button variant="contained" color="default" onClick={handleDeselectAll}>Deselect Everything</Button>
                    </div>
                    <div style={{margin: '10px 0', border: '1px solid #ccc', padding: '10px', borderRadius: '5px'}}>
                        <FormControlLabel
                            control={
                            <Checkbox
                                checked={userData.settings.changeOccurenceRates}
                                onChange={(e) => handleCheckboxChange('changeOccurenceRates', e.target.checked)}
                                name="checkedB"
                                color="primary"
                            />
                            }
                            style={{display: 'flex'}}
                            label={"Change Occurrence Rates"}
                        />
                        <Typography variant="p" style={{ padding: '20px 0' }}>Determines the probability of a specific attribute appearing in the generated character. This is useful when creating multiple characters for a particular scenario or setting, allowing for customized distributions of character attributes.</Typography>
                    </div>
                    <br />
                    {Object.keys(settings).map(key => (
                        <Accordion key={key} style={{backgroundColor: '#fff'}}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls={`${key}-content`}
                                id={`${key}-header`}
                            >
                                <Typography style={{color: 'black'}}>{key}</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <div>
                                <div>
                                    <Button variant="contained" color="default" style={{marginRight: '10px'}} onClick={selectAll(key)}>
                                        Select All
                                    </Button>
                                    <Button variant="contained" color="default" onClick={deselectAll(key)}>
                                        Deselect All
                                    </Button>
                                </div>
                                <div className="settings-list" style={{columns: '280px 2', marginTop: '25px'}}>
                                {settings[key].map((value, i) => {
                                    const valString = value?.value || 'loading..';
                                    const item = settings && Array.isArray(settings[key]) ? settings[key].find(item => item.value === value.value) : null;
                                    const checked = Boolean(item?.isChecked || false);
                                    const weight = item ? item.weight : 10;
                                    return (
                                        <div key={`${key}_key_${i}`} style={{border: '1px solid #eee', breakInside: 'avoid', padding: '5px'}}>
                                        <FormControlLabel
                                            control={
                                            <Checkbox
                                                checked={checked}
                                                onChange={event => handleCheckboxChange(key, {value: value.value, isChecked: event.target.checked, weight: weight})}
                                                name="checkedB"
                                                color="primary"
                                                className={`checked_${checked}`}
                                            />
                                            
                                            }
                                            style={{display: 'flex'}}
                                            label={valString}
                                        />
                                        {checked && (
                                            <React.Fragment>
                                            {userData.settings.changeOccurenceRates && <TogglePill 
                                                onChange={(newValue) => handleCheckboxChange(key, {value: value.value, isChecked: checked, weight: newValue})}
                                                weight={weight}
                                            />}
                                            </React.Fragment>
                                        )}
                                        </div>
                                    )
                                    })}
                                </div>
                                </div>
                            </AccordionDetails>
                        </Accordion>
                    ))}
                </div>
            </Drawer>
        </div>
    );
};

export default AdvancedSettings;