import React, { useEffect, useState, createContext } from "react";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { app } from '../components/user/account/firebase/firebase';
import { db, doc, setDoc, getDoc, Timestamp } from '../components/user/account/firebase/firestore';
import axios from 'axios';
import { API_URL } from '../CONSTANTS';

export const AuthContext = createContext();

const initialUserDataState = {
    settings: {}
}

export const AuthProvider = ({ children }) => {
    const [currentUser, setCurrentUser] = useState(null);
    const [auth, setAuth] = useState(null);
    const [userData, setUserData] = useState(initialUserDataState);
    const [authenticatedError, setAuthenticatedError] = useState(null);
        
    useEffect(() => {
            const auth = getAuth(app);
            setAuth(auth);
            const unsubscribe = onAuthStateChanged(auth, (user) => {
                if (user) {
                    setCurrentUser(user);
                    readUserData(user, (success) => {
                        setUserData(success);
                    }, (failure) => {
                        console.log("No such document!", failure);
                    });
                    fetchDefaultUserSettings();
                } else {
                    // User is signed out
                    setCurrentUser(null);
                    setUserData(null);
                }
                setCurrentUser(user)
            });
        return () => unsubscribe(); // cleanup on unmount
    }, []);

    useEffect(() => {
        if(currentUser && userData){
          // Update the user's data in Firestore whenever currentUser changes
          writeUserData(currentUser.uid, userData).catch((error) => {
            console.error("Error writing user data: ", error);
          });
        }
      }, [userData, setUserData]);

    const requestWithAuth = async (url, method, body, access_token = null, headers = {},attempt = 0) => {
        const MAX_RETRIES = 3; // Maximum number of retries

        try {
            const user = currentUser;
            const token = user ? await user.accessToken : access_token;
            const config = {
                method: method,
                url: `${API_URL}${url}`,
                headers: {
                    'Authorization': `Bearer ${token}`,
                    ...headers,
                },
                data: body
            };

            const response = await axios(config);
            return response.data;

        } catch (error) {
            if (error.response?.data?.message.includes('Token used too early') && attempt < MAX_RETRIES) {
                // Retry the request
                setTimeout(() => {
                    return requestWithAuth(url, method, body, headers, attempt+1);
                }, [1000]);
            } else {
                setAuthenticatedError("We encountered an error when attempting to perform that action. Please refresh the page and try again.")
                // Re-throw the error if it's not about token used too early, or if we've exceeded the max number of retries
                throw error;
            }
        }
    }

    async function writeUserData(userId, data) {
        try {
            await setDoc(doc(db, "users", userId), data);
        } catch (error) {
            console.error("Error writing document: ", error);
        }
    }

    async function readUserData(user, success, failure) {
        const userId = user.uid;
        const docRef = doc(db, "users", userId);
        const docSnap = await getDoc(docRef);
    
        let newUser = null;
    
        const defaultSettings = await fetchDefaultUserSettings(user);
    
        if (docSnap.exists()) {
            const uData = docSnap.data();
            
            if (uData.settings && Object.keys(defaultSettings).every(key => key in uData.settings)) {
                // All keys from defaultSettings are in uData.settings
                newUser = {...uData, settings: { ...uData.settings, dataOrder: defaultSettings.dataOrder }};
                success(newUser);
            } else {
                // Merge uData.settings and defaultSettings. Keys from defaultSettings overwrite those from uData.settings.
                newUser = {
                    ...uData,
                    settings: {...defaultSettings, ...uData.settings, dataOrder: defaultSettings.dataOrder},
                }
                await writeUserData(userId, newUser);
                success(newUser);
            }
        }
    
        if (!newUser) {
            try {
                setTimeout(async () => {
                    newUser = {
                        createdAt: Timestamp.fromDate(new Date()),
                        settings: defaultSettings,
                    }
                    await writeUserData(userId, newUser);
                    success(newUser);
                }, 1000);
            } catch (error) {
                // Handle any errors
                console.log('error creating new user', error);
                failure(error);
            }
        }
    }

    const fetchDefaultUserSettings = async (user) => {
        try {
            const response = await requestWithAuth(`/api/values`, 'get', user.accessToken);
            const dataOrder = response.order;
            const dataValues = response.values;

            // create a new object, using the keys in their original order
            const orderedData = {};
            dataOrder.forEach((key) => {
                orderedData[key] = dataValues[key];
            });

            return {...orderedData, dataOrder: dataOrder, changeOccurenceRates: false};
        } catch (error) {
            console.log('Error fetching data: ', error);
            // alert('Error fetching default settings for new user');
        }
        return null;
    };
    
    const updateUserSettings = ({ key, updatedValues }) => {

        if (Array.isArray(userData.settings[key])) {
            // Clone the part of the state we want to modify
            const updatedKeyItems = [...userData.settings[key]];
            
            const index = updatedKeyItems.findIndex(
                (item) => item.value === updatedValues.value
            );
            // Update the existing item with the new values
            updatedKeyItems[index] = {
                ...updatedKeyItems[index],
                ...updatedValues,
            };
            
            // Return the updated state
            setUserData({
                ...userData,
                settings: {
                    ...userData.settings,
                    [key]: updatedKeyItems,  // Updating only the specified key in the state
                },
            });
        } else {
            // Return the updated state
            setUserData({
                ...userData,
                settings: {
                    ...userData.settings,
                    [key]: updatedValues,  // Updating only the specified key in the state
                },
            });
        }
    }

    const setUserSettings = (newSettings) => {
        setUserData({...userData, settings: {...newSettings}});
    }

    const getOrderedUserData = () => {
        const dataOrder = userData?.settings?.dataOrder;
        const dataValues = userData?.settings;

        if (dataOrder && dataValues) {
        // create a new object, using the keys in their original order
        const orderedData = {};
        dataOrder.forEach((key) => {
            orderedData[key] = dataValues[key];
        });

        return orderedData;
        }
    
        return userData.settings;
    }

    const getOrderedDataFrom = (obj) => {
        const dataOrder = userData?.settings?.dataOrder;
        if (dataOrder && obj) {
            // Create a new object, using the keys in their original order
            const orderedData = {};
            dataOrder.forEach((key) => {
                if (key in obj) {
                    orderedData[key] = obj[key];
                }
            });
            return orderedData;
        }
        return obj;
    }

    return (
        <AuthContext.Provider
        value={{
            currentUser,
            auth,
            requestWithAuth,
            userData,
            setUserData,
            updateUserSettings,
            setUserSettings,
            getOrderedUserData,
            getOrderedDataFrom,
            authenticatedError
        }}
        >
        {children}
        </AuthContext.Provider>
    );
};