import React, { useContext } from 'react';
import { Button } from '@mui/material';
import { getAuth, signOut } from 'firebase/auth';
import { app } from './firebase/firebase';
import { AuthContext } from '../../../context/AuthState';

const auth = getAuth(app);

function LogoutButton() {
  const { setIsLoggedIn } = useContext(AuthContext);

  const handleLogout = async () => {
    try {
      await signOut(auth);
      setIsLoggedIn(false);
      window.location.reload();
      // User is now signed out
      // You can navigate to a new route now
    } catch (error) {
      // Error occurred during sign out
      console.error(error);
    }
  };

  return (
    <Button variant="contained" color="primary" style={{background: '#333'}} onClick={handleLogout}>
      Logout
    </Button>
  );
}

export default LogoutButton;