import { useNavigate } from 'react-router-dom';

export const Link = ({to, callback = () => null, children, ...rest}) => {
  const navigate = useNavigate();
  
  const handleLinkClick = (path) => {
    callback(); // close the character menu here
    navigate(path);
  };

  return (
    <a href="#" {...rest} onClick={() => handleLinkClick(to)}>
      {children}
    </a>
  );
};